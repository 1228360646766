import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const Niihau = () => {
  return (
    <Islands activeIsland="niihau">
      <DesignSystem.H1>Niihau (Ni'ihau)</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default Niihau
